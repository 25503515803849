import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  div: {
    padding: '70% 30px'
  },
  heading: {
    textAlign: 'center'
  }
}));

const SignIn = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.user);

  React.useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, []);

  const handleLoginSuccess = async (credentialResponse) => {

    // Decode the token
    const { credential } = credentialResponse;
    const decoded = jwtDecode(credential);
    console.log(decoded)
    const email = decoded.email;
    const name = decoded.name



    try {
      const res = await axios.post(`${process.env.REACT_APP_RAILS_API}/api/v1/users/google_sign_in`, { email: email, name: name }, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } });
      dispatch(setUser(res.data));
      navigate('/home');
      // Handle successful sign-in (e.g., store user data, redirect, etc.)
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className={classes.div}>
        <h2 className={classes.heading}>Sign In</h2>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onFailure={handleLoginFailure}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default SignIn;
