import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import axios from 'axios';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import MessagesContainer from './MessagesContainer';

const useStyles = makeStyles(() => ({
    vehicletypeList: {
      paddingTop: 0,
    },
    vehicletypeListItem: {
      backgroundColor: '#E4E6C2',
    },
    companyList: {
      paddingTop: 0,
			paddingBottom: 0,
    },
    companyListItem: {
      backgroundColor: '#EDEED5',
    }
  }));

function Community(props) {
    const classes = useStyles();
    const [vehicleType, setVehicleType] = React.useState([]);
    const [openVehicleTypeIndex, setOpenVehicleTypeIndex] = React.useState(null);
    const [selectedVehicleTypeId, setSelectedVehicleTypeId] = React.useState('');
    const [companies, setCompanies] = React.useState([]);
    const [openCompanyIndex, setOpenCompanyIndex] = React.useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = React.useState('');
    const [models, setModels] = React.useState([]);
		const [selectedModelId, setSelectedModelId] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const fetchBlogs = async () => {
            try {
            const response = await axios.get(`${process.env.REACT_APP_RAILS_API}/api/v1/vehicle_types`);
            setVehicleType(response.data);
            } catch (error) {
            console.error('Error fetching blogs:', error);
            }
        };
    
        fetchBlogs();
    }, []);

    const handleVehicleClick = (item, index) => {
			setCompanies([])
			setModels([])
			setOpenVehicleTypeIndex(openVehicleTypeIndex === index ? null : index);
			handleVehicleTypeChange(item)
    };

    const handleVehicleTypeChange = async (item) => {
        const vehicleTypeId = item.id;
        setSelectedVehicleTypeId(vehicleTypeId);
        setLoading(true);
    
        try {
          const response = await axios.get(`${process.env.REACT_APP_RAILS_API}/api/v1/vehicle_types/${vehicleTypeId}/companies`);
          setCompanies(response.data);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
    }

    const handleCompanyClick = (item, index) => {
        setOpenCompanyIndex(openCompanyIndex === index ? null : index);
        handleCompanyChange(item)
    };

    const handleCompanyChange = async (item) => {
        const companyId = item.id;
        setSelectedCompanyId(companyId);
        setLoading(true);
    
        try {
          const response = await axios.get(`${process.env.REACT_APP_RAILS_API}/api/v1/vehicle_types/${selectedVehicleTypeId}/companies/${companyId}/models`);
          setModels(response.data);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
    };

		const handleModelClick = (item, index) => {
			setSelectedModelId(item.id)
		};

    return(
      !selectedModelId ? <List className={classes.vehicletypeList}>
          {vehicleType.map((item, index) => (
            <div key={item.id}>
                <ListItem button key={item.name} onClick={() => handleVehicleClick(item, index)} className={classes.vehicletypeListItem}>
                <ListItemText primary={item.name} />
                {openVehicleTypeIndex === index ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openVehicleTypeIndex === index} timeout="auto" unmountOnExit>
                    <List className={classes.companyList}>
                        {companies.map((item, index) => (
                          <div key={item.id}>
                            <ListItem button key={item.name} onClick={() => handleCompanyClick(item, index)} className={classes.companyListItem}>
                            <ListItemText primary={item.name} />
                            {openCompanyIndex === index ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                                <Collapse in={openCompanyIndex === index} timeout="auto" unmountOnExit>
                                <List className={classes.companyList}>
                                    {models.map((item, index) => (
                                        <ListItem button key={item.name} onClick={() => handleModelClick(item, index)}>
                                        <ListItemText primary={item.name} />
                                        </ListItem>
                                    )
                                    )}
                                </List>
                            </Collapse>
                          </ div>
                        )
                        )}
                    </List>
                </Collapse>
            </ div>
          ))}
        </List> : <MessagesContainer user={props.user} vehicleTypeId={selectedVehicleTypeId} companyId={selectedCompanyId} modelId={selectedModelId}/>
    )
}

export default Community