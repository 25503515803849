import React from 'react';
import { makeStyles } from '@material-ui/core';
import TabPanel from './sdctech/MuiComponents/SimpleTabs';

const useStyles = makeStyles(() => ({
  div: {
	padding: '40% 0px',
	textAlign: 'center'
  }
}));

const SdcTech = () => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      <TabPanel />
    </div>
  );
};

export default SdcTech;
